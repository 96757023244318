<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <user-autocomplete
                            v-if="!user_id"
                            v-model="item.user_id"
                            :label="trans('fields.common.user')"
                            :error-messages="errors.user_id"
                            @input="clearError('user_id')"
                        ></user-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-dialog
                            ref="activeFromDialog"
                            v-model="activeFromModal"
                            :return-value.sync="item.active_from"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="item.active_from"
                                    :label="trans('fields.promoCode.activeFrom')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="item.active_from"
                                scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="activeFromModal = false"
                                >
                                    {{ trans('buttons.common.cancel') }}
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.activeFromDialog.save(item.active_from)"
                                >
                                    {{ trans('buttons.common.apply') }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-dialog
                            ref="activeToDialog"
                            v-model="activeToModal"
                            :return-value.sync="item.active_to"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="item.active_to"
                                    :label="trans('fields.promoCode.activeTo')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="item.active_to"
                                scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="activeToModal = false"
                                >
                                    {{ trans('buttons.common.cancel') }}
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.activeToDialog.save(item.active_to)"
                                >
                                    {{ trans('buttons.common.apply') }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="item.period"
                            :label="trans('fields.promoCode.period')"
                            :error-messages="errors.period"
                            @input="clearError('period')"
                        >
                            <template slot="append">
                                {{trans('period.month.five')}}
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="item.discount"
                            :label="trans('fields.promoCode.discount')"
                            :error-messages="errors.discount"
                            @input="clearError('discount')"
                        >
                            <template slot="append">%</template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="itemId" color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="blue darken-1" text @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    import UserAutocomplete from "../user/UserAutocomplete";
    export default {
        name: "PromoCodeActivationForm",
        mixins: [formValidate],
        components: {
            UserAutocomplete
        },
        props: {
            itemId: {
                type: [Number, undefined],
            },
            promo_code_item_id: {
                type: [Number, undefined],
            },
            user_id: {
                type: [Number, undefined],
            }
        },
        data(){
            return {
                item: {
                    id: null,
                    promo_code_item_id: this.promo_code_item_id || null,
                    user_id: this.user_id || null,
                    active_from: null,
                    active_to: null,
                    period: null,
                    discount: null,
                },
                options: {},
                activeFromModal: false,
                activeToModal: false,
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                // this.loadOptions();
                if (this.itemId){
                    axios.get(this.route('admin.promoCodes.activations.show', this.itemId)).then( response => {
                        this.item = response.data;
                        this.item.active_from = this.$options.filters.dateFormat(this.item.active_from, 'YYYY-MM-DD');
                        this.item.active_to = this.$options.filters.dateFormat(this.item.active_to, 'YYYY-MM-DD');
                    }).catch( err => {
                        console.log(err);
                    })
                }
            },
            loadOptions(){
                axios.post(this.route('admin.options'), {
                    cpaNetworkScopes: true,
                }).then( response => {
                    this.options = response.data;
                })
            },
            save() {
                if (!this.item.id) {
                    axios.post(this.route('admin.promoCodes.activations.store'), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                } else {
                    axios.post(this.route('admin.promoCodes.activations.update', this.itemId), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                }
            },
        },
        computed: {
            formTitle() {
                return !this.itemId
                    ? this.trans('pages.promoCodeActivation.newDialogTitle')
                    : this.trans('pages.promoCodeActivation.editDialogTitle');
            },
        }
    }
</script>
